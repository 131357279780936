.static h3 {
  text-align: center;
  color: #f48000;
}
.static h2 {
  font-size: 36px;
}
.static span {
  font-size: 14px;
  font-weight: 600;
}
.static {
  width: 100%;
}
