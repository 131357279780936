.admin-prod-edit {
  flex-grow: 1;
  background-color: white;
  margin: 10px auto;
  width: 85%;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #dadada;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}
.prod-edit-general-info {
  width: 90%;
  display: flex;
  flex-direction: row;
  background-color: #2196f317;
  padding: 1rem;
  margin: 1rem;
  border-radius: 7px;
}
.prod-edit-img {
  height: 135px;
  margin-right: 10px;
}
.prod-edit-name {
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 1.2em;
}
.prod-edit-description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  line-height: 1.3em;
  height: 5em;
}
.prod-edit-other-info {
  width: 90%;
  text-align: left;
}

.prod-edit-option-headline {
  width: 30%;
  margin: 0 0 5px;
  font-weight: 500;
}
.prod-edit-block > a {
  color: #0097ec;
}
.prod-edit-block {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}
.prod-edit-btn-block {
  width: 90%;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.delete-prod-modal {
  text-align: center;
}
.delete-prod-modal-btns {
  display: flex;
  justify-content: space-evenly;
}
