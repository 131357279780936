.admin-prod-form {
  width: 90%;
}
.admin-prod-form p {
  margin: 1rem 0 0;
  font-weight: 600;
  color: #0074b5;
}

.admin-prod-form .prod-edit-block {
  align-items: flex-end;
}

.admin-prod-form input {
  padding: 8px 5px;
  outline: none;
}

.admin-prod-form input[type='text'] {
  width: 40%;
}

.admin-prod-form input[type='number'] {
  width: 10%;
}

.admin-prod-form input[type='checkbox'] {
  cursor: pointer;
}

.admin-prod-form textarea {
  max-width: 100%;
  min-width: 30%;
  min-height: 7rem;
  max-height: 20rem;
  height: 7rem;
  outline: none;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.err-inp {
  font-size: 12px;
  color: #ff5d5d;
}
