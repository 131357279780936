.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination ul {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0px;
}
.pagination li {
  padding: 5px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.selected {
  background-color: #f48000;
  color: white;
}
.selected a {
  color: white;
}
.pagination-panel {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.catalog {
  display: grid;
  grid-template-columns: 23% 23% 23% 23%;
  gap: 15px;
  /* margin-left: 10px; */
  width: 100%;
}
.catalog a {
  overflow: hidden;
}
.product-img {
  min-height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: 1s;
}
.product-link {
  height: 197px;
  display: flex;
  align-items: center;
}
.product-img:hover {
  /* height: 130px; */
  transform: scale(1.2);
}
.no-img {
  width: 65%;
}
.seccess-add {
  position: fixed;
  top: 100px;
  left: 38%;
  background-color: #c4c3c3;
  color: black;
  padding: 10px;
  display: flex;
  border-radius: 9px;
  align-items: center;
  animation: fadeInDown 2s both;
  z-index: 12;
}
.select-sort {
  margin: 10px 0;
}
.modal-product-img {
  height: 110px;
  margin-right: 20px;
}
.modal-product-content {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #f47e00;
}
.modal-product-content h3 {
  color: #f48000;
}
.modal-qestion-text {
  font-weight: 500;
  font-size: larger;
}
.modal-btn-addcart {
  border: none;
  color: white;
  background-color: #a4a3a3;
  padding: 10px 20px;
  width: 45%;
  cursor: pointer;
}
.modal-btn-addcart:hover {
  background-color: #f47e00;
  transition: background-color 0.5s;
}
/* #0097EC */
.modal-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0px, -100%, 0px);
  }
  50% {
    opacity: 1;
    transform: none;
  }
  100% {
    opacity: 0;
    transform: translate3d(0px, 100%, 0px);
  }
}
@media (max-width: 1050px) {
  .catalog {
    grid-template-columns: 31% 31% 31%;
  }
}
@media (max-width: 715px) {
  .catalog {
    grid-template-columns: 45% 45%;
  }
}
@media (max-width: 340px) {
  .catalog {
    grid-template-columns: 45% 45%;
  }
}
