.admin-all-products {
  flex-grow: 1;
  background-color: white;
  margin: 10px auto;
  width: 85%;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #dadada;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.instock-img {
  height: 35px;
}

.all-products-admin-hedline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
}

.all-model-table {
  width: 95%;
  margin: 1rem auto;
}
.all-model-table-headline {
  border-bottom: 1px solid #f19c00;
  margin-bottom: 1rem;
}

.all-model-block {
  width: 100%;
}

.model-headline {
  background-color: #51adf61f;
  padding: 1rem 5px;
  border-radius: 5px;
  width: 100%;
}

.id-panel {
  margin-right: 1rem;
  font-weight: 600;
}

.arr-to-stock {
  height: 15px;
}

.model-block {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.models-producs-table {
  width: 90%;
}

.all-model-table td {
  text-align: center;
}
.product-name-block img {
  height: 60px;
}
.add-all-prod-btn {
  margin-left: 1rem;
}

.product-name-block {
  display: flex;
  align-items: center;
}

.th-product-name {
  width: 40%;
}
.inp-model-search {
  padding: 8px 4px;
  margin-left: 1rem;
  width: 95%;
  outline: none;
  border: 1px solid #6196f378;
}
