.admin-entry {
  flex-grow: 1;
  background-color: white;
  margin: 10px auto;
  width: 85%;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #dadada;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.admin-inp {
  padding: 7px 10px;
  width: 90%;
  outline: none;
}
.admin-pass {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid;
  padding: 5px 10px;
}

.admin-pass img {
  height: 20px;
  cursor: pointer;
}
.admin-inp-pass {
  border: none;
  outline: none;
}

.entry-btn {
  border: none;
  outline: none;
  background-color: #f48000;
  color: white;
  padding: 10px 25px;
  margin: 10px;
  cursor: pointer;
}

.entry-btn:disabled,
.entry-btn[disabled] {
  background-color: #ababab;
}

.err-enter {
  background-color: #ffa0a093;
  color: #ff5a5a;
  border-radius: 15px;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
}
