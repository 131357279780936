.header {
  background-color: white;
  margin-bottom: 1rem;
}
.header-all {
  display: flex;
  align-items: center;
}
.inst-img {
  height: 25px;
}
.viber-field {
  display: flex;
  align-items: center;
}
.inst-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.header-optional {
  width: 90%;
  margin-left: 10px;
}
.header-handler {
  color: #0097ec;
  text-transform: uppercase;
  font-size: 16px;
}
.header-search {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.inp-search {
  width: 93%;
  padding: 11px 10px;
  outline: none;
  border: 1px solid #d0d0d0;
}
.header-btn {
  width: 38px;
  height: 40px;
  background-color: #f48000;
  border: none;
  color: white;
  display: flex;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  margin: 0;
  cursor: pointer;
}
/* .header-btn img{
    width: 80%;
} */
.contacts-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  font-size: 12px;
}
.viber {
  height: 20px;
  /* margin-left: 15px; */
}
.contact-numbers {
  display: flex;
  align-items: center;
}
.phone-number {
  text-decoration: none;
  color: #000;
}
.schedule {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
}
.search-block {
  display: flex;
  align-items: center;
  width: 91%;
}
.count-cart {
  position: relative;
  top: -4px;
  left: 45px;
  background-color: #0097ec;
  color: white;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-size: 12px;
}
.block-cart-header {
  display: flex;
}

@media (max-width: 715px) {
  .contacts-header {
    align-items: flex-start;
    flex-direction: column;
  }
  .schedule {
    width: 100%;
  }
}
@media (max-width: 400px) {
  .header-all {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .header-optional {
    width: 100%;
    margin-left: 10px;
    text-align: center;
  }
}
