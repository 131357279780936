.image-item img {
  height: auto;
  width: 100%;
}
.image-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 33%);
  gap: 5px;
}
.image-item {
  position: relative;
  width: 100%;
  border: 1px solid rgb(209, 209, 209);
}
.remove-img-btn {
  position: absolute;
  left: 80%;
  top: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 30px;
}
.remove-img-btn img {
  height: 100%;
}
