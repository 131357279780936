.category-block {
  width: 80%;
}
.category-page {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.subcategory {
  border: 1px solid #e1e1e1;
  width: fit-content;
  padding: 10px;
  margin: 0px 15px 10px;
  height: auto;
  width: 150px;
  overflow: hidden;
}
.subcategory:hover img {
  transform: scale(1.1);
  transition: transform 0.5s;
}
.subcategory a {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
  color: #2196f3;
}
.subcategory img {
  height: 100px;
  margin-bottom: 10px;
}
.auto-animate {
  position: relative;
  animation: drift 10s linear infinite;
}
.zap-animate {
  position: relative;
  animation: drift 10s linear infinite;
  animation: colo 10s linear infinite;
}
/* .animate-background{
  position: fixed;
  z-index: -2;
  top: 30px;
  left: -15%;
  height: 600px;
  width: 600px;
  border-radius: 50%;
  background: url(./assets/icons/span.vertical-menu-ico-6.png) no-repeat;
  background-position: center bottom;
  animation: drift 15s linear infinite;
} */
@keyframes drift {
  0% {
    left: 1px;
    transform: scale(1, 1);
  }
  49% {
    left: 90%;
    transform: scale(1, 1);
  }
  50% {
    transform: scale(-1, 1);
  }
  99% {
    left: 1px;
    transform: scale(-1, 1);
  }
  100% {
    left: 1px;
    transform: scale(1, -1);
  }
}
@keyframes colo {
  0% {
    left: 1px;
    transform: rotate(0deg);
  }
  50% {
    left: 90%;
    transform: rotate(360deg);
  }
  100% {
    left: 1px;
    transform: rotate(0deg);
  }
}
@media (max-width: 900px) {
  .category-page {
    grid-template-columns: repeat(2, 1fr);
  }
  .subcategory {
    width: 80%;
  }
}
@media (max-width: 580px) {
  .category-page {
    grid-template-columns: repeat(1, 1fr);
  }
  .subcategory {
    width: 80%;
  }
}
@media (max-width: 480px) {
  .subcategory {
    width: 100%;
  }
}
