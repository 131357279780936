.admin-header {
  background-color: #f48000;
  color: white;
  padding: 5px 8%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.admin-logo-img {
  height: 50px;
}
.admin-header-link {
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.log-out-btn {
  cursor: pointer;
}
