.select-category-list {
  list-style: none;
  margin: 0;
  padding: 0 5px;
  overflow: auto;
  height: 15rem;
  scrollbar-width: thin;
  border: 1px solid #f1f1f1;
}

.select-category-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
