.container {
  margin: 0px 12%;
  padding: 10px 20px;
  background-color: white;
}
.home {
  min-height: 500px;
}
.logo-img {
  height: 130px;
}

/* .animate-background{
  position: fixed;
  z-index: -2;
  top: 30px;
  left: -15%;
  height: 600px;
  width: 600px;
  border-radius: 50%;
  background: url(./assets/icons/span.vertical-menu-ico-6.png) no-repeat;
  background-position: center bottom;
  animation: drift 15s linear infinite;
} */
/* @keyframes drift {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  } */
.btn {
  background-color: #f48000;
  color: white;
  padding: 10px 1rem;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 1s;
}

.btn:hover {
  background-color: #ffa03b;
}
.btn:disabled,
.btn[disabled] {
  background-color: #7c7c7c;
}

@media (max-width: 1050px) {
  .container {
    margin: 0px 6%;
  }
}
@media (max-width: 650px) {
  .container {
    margin: 0px 1%;
  }
}
@media (max-width: 500px) {
  .container {
    margin: 0px 0%;
  }
  .home {
    flex-direction: column;
  }
}
