.footer {
  background-color: #f48000;
  color: white;
  padding-top: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 12%;
}
.footer a {
  color: white;
}
.menu-foot {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 30%;
}
.footer p {
  margin: 0px;
}
@media (max-width: 400px) {
  .footer {
    flex-direction: column;
    align-items: center;
  }
  .menu-foot {
    width: 100%;
  }
}
