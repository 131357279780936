.succes-headline {
  color: #f48000;
  font-size: 2rem;
  margin: 0;
}
.succes-order-content,
.succes-order-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.succes-order-content img {
  height: 150px;
}
