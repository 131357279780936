.prod-quantity {
  display: flex;
  justify-content: space-around;
  width: 25%;
  border: 1px solid gray;
  padding: 5px 0px;
  font-size: 20px;
  cursor: pointer;
}
.product {
  width: 100%;
}
.prod-img {
  height: max-content;
  max-height: 250px;
  width: 100%;
  /* transition: 1s; */
}
.product-quantity-control {
  width: 20%;
  text-align: center;
}
.inp {
  padding: 10px 5px;
  margin-bottom: 5px;
  outline: none;
}
.modal-product-img {
  height: 100px;
  width: auto;
}
.modal-product-img img {
  height: 100%;
}
.madal-product {
  display: flex;
  flex-direction: row;
}
.to-cart-btn:disabled {
  background-color: #c6c6c6;
  color: #000;
}
.slider-container {
  width: 100%;
}
.modal-create-order-form {
  display: flex;
  flex-direction: column;
}

.description-prod {
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
}
.product h2 {
  text-align: center;
}
.garant {
  text-decoration: revert;
  -webkit-text-decoration: revert;
  color: #0090c9;
  font-size: 13px;
}
.all-description {
  padding: 0px 25px;
}
.btn-arrow {
  right: -25px;
  position: absolute;
}
.btn-arrow-left {
  position: absolute;
  left: -20px;
}
.btn-arrow-left img,
.btn-arrow img {
  width: 60%;
  cursor: pointer;
}

.desc-prod {
  width: 40%;
}
.desc-text {
  font-size: 13px;
  color: gray;
}
.desc-prod p {
  margin: 3px 0px;
}
.desc-prod b {
  font-size: 22px;
  margin-left: 5px;
}
.price-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 25px 0px;
}
.byn {
  font-size: 22px;
  font-weight: 600;
  color: #f48000;
}
.price {
  font-size: 22px;
  font-weight: 600;
}
.pay-metod {
  display: flex;
  align-items: flex-end;
  border-bottom: 1px dotted #c6c6c6;
  padding-bottom: 10px;
}
.status-qu {
  display: flex;
  align-items: center;
  font-size: 13px;
}
.img-prod {
  width: 50%;
  display: flex;
  align-items: center;
}
.to-cart-btn {
  background-color: #ff9800;
  border: none;
  padding: 10px;
  color: white;
  margin: 10px 0px;
  cursor: pointer;
}
.product .slider-control-bottomcenter ul {
  flex-wrap: wrap;
  justify-content: center;
  /* top: 50px !important; */
}
@media (max-width: 780px) {
  .description-prod {
    align-items: center;
    flex-direction: column;
  }
  .img-prod {
    width: 80%;
    margin-bottom: 50px;
    max-height: 250px;
    display: block;
  }
  .desc-prod {
    width: 80%;
  }
}
