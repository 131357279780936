.select-country-phone {
  border: none;
  width: 80px;
  outline: none;
}
.country-image {
  height: 10px;
  width: auto;
}
.mask-inp-phone {
  width: 80%;
  border: none;
  outline: none;
  font-size: 14px;
}
.phone-input {
  border: 1px solid grey;
  border-radius: 2px;
  padding: 0px 5px;
  display: flex;
  justify-content: space-between;
}
