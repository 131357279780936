.category-pagination-title {
  border-bottom: 2px solid #f48000;
  width: fit-content;
}
.category-product {
  display: flex;
  justify-content: space-between;
}
.category-pagination-panel {
  width: 70%;
}
.category-prod-option {
  width: 100%;
  margin-left: 15px;
}
.category-prod-option .btn-add-cart,
.btn-to-cart {
  width: 40%;
  margin-top: 10px;
  transition: 0.7s;
}
.btn-to-cart {
  height: 35px;
  background-color: #a4a3a3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  margin: 10px 0px;
  padding: 5px 0;
  text-align: center;
}
.btn-to-cart:hover {
  background-color: #f48000;
}
.block-category-item {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e1e1e1;
}
.category-catalog-header {
  display: flex;
  justify-content: space-between;
}
.category-description-page {
  font-size: 14px;
  -webkit-line-clamp: 6;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.category-product-title a {
  color: #2196f3;
  font-size: 18px;
  font-weight: 500;
  text-decoration: underline;
}

.filters-block {
  width: 93%;
  padding: 1rem;
  border: 1px solid #cccccc;
  border-radius: 6px;
  margin: 0 0 1rem;
}

.filters-block h3 {
  margin: 0;
  color: #6196f3;
}
.price-filter {
  padding: 0 0 1rem;
}
.range-slider .range-slider__range {
  background: #ffaa4c !important;
}
.range-slider .range-slider__thumb {
  width: 17px !important;
  height: 17px !important;
  background: #f48000 !important;
}
.select-brand {
  width: 60%;
}
.filter-options {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.btn-set-filters {
  outline: none;
  border: none;
  background-color: #f48000;
  color: white;
  padding: 0.5rem 1rem;
  cursor: pointer;
}
@media (max-width: 800px) {
  .category-pagination-panel {
    width: 100%;
  }
  .category-product {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .delivery-cat {
    display: none;
  }
}
