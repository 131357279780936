.menu-information {
  background-color: #f48000;
  color: white;
}
.menu-information nav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
  font-size: 15px;
  margin: 10px 0 0 30px;
  flex-wrap: wrap;
  gap: 7%;
}
.menu-information a {
  color: #fff;
  text-decoration: none;
}

.menu {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  height: 105px;
  overflow: auto;
}
.item-menu {
  width: 12%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
}
.item-menu a {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #000;
}
.item-menu:hover {
  color: #fff;
  background-color: #f48000;
}
.item-menu:hover a {
  color: #fff;
  text-decoration: none;
}
.menu-information a:hover {
  color: #fff;
  text-decoration: none;
  text-decoration: underline;
  text-underline-position: from-font;
}
.item-menu:hover img {
  -webkit-filter: brightness(10);
  filter: brightness(10);
  transform: scale(1.25);
  transition: transform 0.5s;
}
.item-menu:hover p {
  color: #fff;
}
.item-menu img {
  height: 70px;
}
.item-menu p {
  font-size: 12px;
  font-weight: 600;
  color: #f48000;
  text-align: center;
  margin: 0;
}
.category {
  margin-bottom: 20px;
  padding: 0px 12%;
}
.header-mobile {
  display: none;
}
@media (max-width: 1050px) {
  .category {
    margin-bottom: 20px;
    padding: 0px 0%;
  }
  .item-menu {
    width: auto;
  }
}
@media (max-width: 700px) {
  .header-desctop {
    display: none;
  }
  .header-mobile {
    display: block;
  }
  .header-mobile a {
    display: block;
    font-size: 14px;
    color: #4e4d4d;
    padding: 10px 0px;
    border-bottom: 1px dotted;
  }
}
