.home-content a {
  color: #0097ec;
}
.home-content h1,
h2 {
  color: #f48000;
  margin: 1.7rem 0 0.7rem;
}

.home-content p {
  margin: 0;
}
