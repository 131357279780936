.admin-selmade-edit {
  flex-grow: 1;
  background-color: white;
  margin: 10px auto;
  width: 85%;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #dadada;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.add-prod-btn {
  margin: 1rem 0;
}
